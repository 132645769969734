import { NotFound } from 'app/features/boundary/notFound'
import { HomeLayout } from 'app/features/home/HomeLayout/index'

import { NextPageWithLayout } from './_app'

const Page: NextPageWithLayout = () => {
  return <NotFound />
}

Page.getLayout = (page) => <HomeLayout>{page}</HomeLayout>

export default Page
