import { Button } from 'app/components/Button'
import { useRouter } from 'next/router'

export const NotFound = () => {
  const router = useRouter()
  return (
    <div className="relative z-20 flex h-full w-full flex-col items-center justify-center gap-4">
      <p className="absolute z-0 text-[168px] font-bold text-gray-300 opacity-50 md:text-[268px]">
        404
      </p>
      <h2 className="z-20 text-2xl font-bold text-white md:text-4xl">Page not found</h2>
      <Button
        mode="white"
        className="text-dark z-20 w-fit px-4 py-2 md:px-6 md:py-4"
        onClick={() => router.replace('/')}
      >
        Back to home
      </Button>
    </div>
  )
}
